<template>
  <div v-loading="logisticsLoading">
    <sui-error v-if="error" :data="error"></sui-error>
    <sui-form
      v-else
      ref="spuForm"
      :list="formList"
      :defaultdata="formData"
      @submit="submit"
      submitTitle="common.submitAndNext"
    />
  </div>
</template>

<script>
import SuiForm from "@/components/s-ui/form";
import SuiError from "@/components/s-ui/error";
import {
  GetLogisticsPrice,
  EditLogisticsPrice,
  AddLogisticsPrice,
} from "@/utils/api";
export default {
  components: { SuiForm, SuiError },
  data() {
    return {
      logisticsLoading: false,
      error: null,
      edit: "edit",
      formList: [
        {
          type: "input",
          name: "id",
          hidden: true,
        },
        // {
        //   type: "country",
        //   name: "fromCountry",
        //   label: "pricing.fromCountry",
        //   rules: [
        //     {
        //       required: true,
        //     },
        //   ],
        // },
        {
          type: "countrylist",
          name: "fromCountryId",
          label: "pricing.fromCountry",
          rules: [
            {
              required: true,
            },
          ],
        },
        // {
        //   type: "city",
        //   name: "fromCity",
        //   label: "pricing.fromCity",
        //   rules: [
        //     {
        //       required: true,
        //     },
        //   ],
        // },
        {
          type: "citylist",
          name: "fromCityList",
          label: "pricing.fromCity",
          rules: [
            {
              required: true,
            },
          ],
        },
        // {
        //   type: "tocountry",
        //   name: "toCountry",
        //   label: "pricing.toCountry",
        //   rules: [
        //     {
        //       required: true,
        //     },
        //   ],
        // },
        {
          type: "tocountrylist",
          name: "toCountryId",
          label: "pricing.toCountry",
          rules: [
            {
              required: true,
            },
          ],
        },
        // {
        //   type: "tocity",
        //   name: "toCity",
        //   label: "pricing.toCity",
        //   rules: [
        //     {
        //       required: true,
        //     },
        //   ],
        // },
        {
          type: "tocitylist",
          name: "toCityList",
          label: "pricing.toCity",
          rules: [
            {
              required: true,
            },
          ],
        },
        // {
        //   type: "radio",
        //   name: "freightMode",
        //   label: "pricing.freightMode",
        //   rules: [{ required: true }],
        //   range: [
        //     {
        //       label: "pricing.sea",
        //       value: "sea",
        //       icon: "icon-haiyun",
        //     },
        //     {
        //       label: "pricing.air",
        //       value: "air",
        //       icon: "icon-icon-aviation",
        //     },
        //   ],
        // },
        {
          type: "goodsTypeList",
          name: "goodsTypeList",
          label: "pricing.goodsTypeList",
          rules: [{ required: true }],
        },
        {
          name: "contactInfo",
          label: "pricing.contactInfo",
          rules: [
            {
              required: true,
            },
          ],
        },
        {
          type: "radio",
          name: "way",
          label: "pricing.way",
          rules: [{ required: true }],
          range: [
            {
              label: "pricing.land",
              value: 101,
              icon: "icon-banyunfei",
            },
            {
              label: "pricing.sea",
              value: 201,
              icon: "icon-haiyun",
            },
            {
              label: "pricing.air",
              value: 301,
              icon: "icon-icon-aviation",
            },
          ],
        },
        {
          type: "radio",
          name: "wayMode",
          label: "pricing.wayMode",
          rules: [{ required: true }],
          range: [
            {
              label: "pricing.cmb",
              value: 101,
            },
            {
              label: "pricing.weight",
              value: 201,
            },
          ],
        },
        {
          name: "period",
          label: "product.leap",
          rules: [
            {
              required: true,
            },
          ],
        },
        {
          type: "logisticsladderlist",
          name: "logisticsLadderList",
          label: "product.logisticsladderlist",
          rules: [
            {
              required: true,
            },
          ],
        },
        // {
        //   type: "price",
        //   name: "price",
        //   label: "pricing.price",
        //   rules: [
        //     {
        //       required: true,
        //     },
        //   ],
        // },
        // {
        //   type: "price",
        //   name: "customFees",
        //   label: "pricing.customFees",
        //   rules: [
        //     {
        //       required: true,
        //     },
        //   ],
        // },
        {
          type: "textarea",
          name: "detailAddress",
          label: "pricing.detailAddress",
          rules: [
            {
              required: true,
            },
          ],
        },
      ],
      formData: {
        isHidden: 0,
        freightMode: "sea", //  sea海运 air空运
        fromCountry: "",
        toCountry: "",
        price: 1,
        contactInfo: "",
        detailAddress: "",
        way: 101,
        wayMode: 101,
        period: 1,
      },
    };
  },
  created() {
    const id = Number(this.$route.query.id);
    id && this.getDetail();
  },
  methods: {
    getDetail() {
      this.logisticsLoading = true;
      GetLogisticsPrice({ id: Number(this.$route.query.id) })
        .then((res) => {
          this.$nextTick(() => {
            // this.$refs["spuForm"].setForm(res.data);
            console.log(
              res.data.fromCountryId,
              "fromCountryId",
              res.data.toCountryId
            );
            this.$store.commit("setcurrentCountry", res.data.fromCountryId);
            this.$store.commit("setTocurrentCountry", res.data.toCountryId);
            this.$store.commit("setToCountry", res.data.toCountry);
            res.data.logisticsLadderList[0].price =
              res.data.logisticsLadderList[0].price / 7.25526;
            res.data.logisticsLadderList[1].price =
              res.data.logisticsLadderList[1].price / 7.25526;
            console.log("GetLogisticsPrice-res", res.data);
            this.formData = res.data;
          });
          this.error = null;
          this.logisticsLoading = false;
        })
        .catch((err) => {
          console.log("GetLogisticsPrice-err", err);
          this.error = err;
          this.logisticsLoading = false;
        });
    },
    submit(data) {
      this.logisticsLoading = true;
      const toCountry = localStorage.getItem("toCountry");
      const fn = data.id ? EditLogisticsPrice : AddLogisticsPrice;
      data.toCountry = data.toCountry == toCountry ? data.toCountry : toCountry;
    //   console.log("data00000000000000000", data, fn);
      //   this.logisticsLoading = false;
      // data.fromProvince = data.fromCity[0];
      // data.fromCity = data.fromCity[1];
      // data.toProvince = data.toCity[0];
      // data.toCity = data.toCity[1];

      fn(data)
        .then(() => {
          this.$message({
            type: "success",
            message: this.$t("common.createSuccess"),
          });
          this.$router.push("/pricing/list");
          this.error = null;
          this.logisticsLoading = false;
        })
        .catch((err) => {
          console.log("EditLogisticsPrice-err", err);
          this.error = err;
          this.logisticsLoading = false;
        });
    },
  },
};
</script>